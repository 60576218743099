import React, { Component } from "react";
import * as moment from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { DashboardService } from "../../../services/dashboard.service";
import _ from 'lodash';

/**
 * Detail navbar component
 */

class DetailNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDateFilter: false,
      showCustomDateFilter: false,
      dashboardConfigData : [],
      selectedDate: moment().format('DD MMM YY'),
      selectedFilter: 'Today',
      rangeSelected: '',
      startDate: '',
      endDate: '',
      trigger: '',
      selectedTab: "",
      tabsInitDataId: [],
      chartsGData: {},
      widgetData: [],
      date: [new Date(), new Date()],
      dateListOne: [
        {
          'date': [moment().startOf('day'), + moment().endOf('day')],
          'filter': 'Today'
        },
        {
          'date': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
          'filter': 'Yesterday'
        },
        {
          'date': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
          'filter': 'This week'
        },
        {
          'date': [moment().startOf('month'), moment().endOf('month')],
          'filter': 'This month'
        },
        {
          'date': [moment().quarter(moment().quarter()).startOf('quarter'), moment().quarter(moment().quarter()).endOf('quarter')],
          'filter': 'This quarter'
        },

      ],
      dateListTwo: [
        {
          'date': [moment().subtract(6, 'days'), moment()],
          'filter': 'Last 7 days'
        },
        {
          'date': [moment().subtract(29, 'days'), moment()],
          'filter': 'Last 30 days'
        },
        {
          'date': [moment().subtract(3, 'month'), moment()],
          'filter': 'Last 3 months'
        },
        {
          'date': [moment().subtract(6, 'month'), moment()],
          'filter': 'Last 6 months'
        },
        {
          'date': '',
          'filter': 'Custom'
        },
      ]
    }

    this.showFilter = this.showFilter.bind(this);

  }

  componentDidMount() {
    let thisMonthRange = moment().startOf('month').format('DD MMM') + " - " + moment().endOf('month').format('DD MMM');
    this.setState({
      selectedFilter: "This month",
      selectedDate: thisMonthRange
    })
      DashboardService.getConfig().then(
      response => {
        this.setState(prevState => ({
          ...prevState,
          dashboardConfigData : response.responseData
        }))
        this.getWidgets();
        if(!this.state.chartsGData.length){
            setTimeout(() => this.getWidgets(), 1000);
        }
      },
      error => {

      }
    );
  }

  getChartData = (code) => {
    DashboardService.getData(code).then(
        response => {
          this.setState( prevState => ({
              ...prevState,
              chartsGData: {
                  ...prevState.chartsGData,
                  [code]:response.responseData
              }
          }))
          localStorage.setItem("chartData",JSON.stringify(this.state.chartsGData));
        },
        error => {
        }
      )
  }

  updateVisuals = () => {
    this.setState({
      trigger: true
    })
    this.props.history.push({pathName: "/dashboards", state: {trigger: this.state.trigger}})
    this.getWidgets();
    this.setState({
      trigger: false
    })
    this.props.history.push({pathName: "/dashboards", state: {trigger: this.state.trigger}});
    this.getWidgets();
    setTimeout(() => this.getWidgets(), 1000);
  }

  showFilter = () => {
    this.setState ({
      showDateFilter: true,
    })
  }

  async getDate(dateFilter) {
      await this.setState ({
        selectedFilter: dateFilter.filter,
        selectedDate: moment(dateFilter.date[0]._d).format('DD MMM') + ' - ' + moment(dateFilter.date[1]._d).format('DD MMM'),
        showDateFilter: false,
        showCustomDateFilter: false,
        startDate: dateFilter.date[0],
        endDate: dateFilter.date[1],
      })
      await localStorage.setItem("startDate", moment(dateFilter.date[0]));
      await localStorage.setItem("endDate", moment(dateFilter.date[1]));
      this.updateVisuals();
  }

  getCustomDate = () => {
    this.setState ({
      showCustomDateFilter: true,
    })
  }

  onDateChange = (date) => {
    this.setState({ date, showCustomDateFilter: false, showDateFilter: false })
    let finalRange = moment(date[0]).format('DD MMM') + " - " + moment(date[1]).format('DD MMM');
    this.setState({
      selectedFilter: 'Custom',
      selectedDate: finalRange,
    })
    localStorage.setItem("startDate", moment(date[0]));
    localStorage.setItem("endDate", moment(date[1]));
    this.updateVisuals();
    this.getWidgets();
    setTimeout(() => this.updateVisuals(), 1000);
  }

  getWidgets = () => {
    let data = this.state.dashboardConfigData;
    let dashboardWidget = _.chain(data).first().get("widgetCharts").groupBy("name").value();
    let widgetArray = _.chain(dashboardWidget).map();
    widgetArray = JSON.stringify(widgetArray);
    widgetArray = JSON.parse(widgetArray);
    let id = [];
    widgetArray.map(code => id.push(code[0].id));
    id.map(code => this.getChartData(code));

    let chartDetails = localStorage.getItem("chartData");
    chartDetails = JSON.parse(chartDetails);
    chartDetails = _.chain(chartDetails).map();
    chartDetails = JSON.stringify(chartDetails)
    chartDetails = JSON.parse(chartDetails);

    let chartData = [];
    chartDetails.map(details => chartData.push(details.data[0]))
    this.setState({
      widgetData: chartData
    })
  }

  render() {
    return (
      <nav className="navbar navbar-light col-md-12 col-lg-12 col-xl-12 detailNavHeight navBg tabText detailNavBarbuiltBorder">
        <div className="row textColor d-none d-md-flex d-lg-flex">
          {this.state.widgetData.map(data => (
            <div className="" key={data.headerName}>
                <p className="pl-4 mb-1">{data.headerName}</p>
                {data.headerName === "Average Rating" ?
                  (<p className="pl-4 mb-1 largeNum">{(Math.round(data.headerValue * 100) / 100).toFixed(1)}</p>) : (<p className="pl-4 mb-1 largeNum">{data.headerValue}</p>)}
            </div>
          ))}
        </div>
        { !this.state.showDateFilter && (
          <div className="row  d-sm-flex d-md-flex d-lg-flex">
              {/*}<div className="vl d-none d-md-flex d-lg-flex"></div>
              <div className="p-3 customPadding">
                <h5 className="">North Area B</h5>
                <p className="">Sweden</p>
              </div>*/}
              <div className="vl"></div>
              <div className="p-3 pr-4 customPadding cursorStyleOne" onClick={this.showFilter}>
                <h5 className="">{this.state.selectedFilter}</h5>
                <p className="">{this.state.selectedDate}</p>
              </div>
          </div>
        )}
        { this.state.showDateFilter && (
          <div className="row d-sm-flex d-md-flex d-lg-flex">
              <div className="vl d-none d-md-flex d-lg-flex"></div>
              { this.state.showCustomDateFilter && (<DateRangePicker className="pb-1" onChange={this.onDateChange} value={this.state.date} />) }
              { !this.state.showCustomDateFilter && (
                <div className="customPadding">
                  <div className="row ml-3 customMargin">
                    <div className="">
                      { this.state.dateListOne.map(dateFilter =>
                        <p id="dateList" key={dateFilter.filter} className={ this.state.selectedFilter === dateFilter.filter ? "activeFilter" : " "}
                          onClick={() => this.getDate(dateFilter)}>{dateFilter.filter}</p>
                    )}
                    </div>
                    <div className="ml-4 pb-2 customMargin">
                      { this.state.dateListTwo.map(dateFilter =>
                        <p id="dateList" key={dateFilter.filter} className={ this.state.selectedFilter === dateFilter.filter ? "activeFilter" : " "}
                          onClick={dateFilter.filter !== "Custom" ? (() => this.getDate(dateFilter)) : (() => this.getCustomDate())}>{dateFilter.filter}</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="vl"></div>
              <div className="p-3 pr-4 customPadding cursorStyleOne" onClick={this.showFilter}>
                <h5 className="">{this.state.selectedFilter}</h5>
                <p className="">{this.state.selectedDate}</p>
              </div>
          </div>

        )}
      </nav>
    )
  }
}

export default DetailNavBar;

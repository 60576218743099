import React from 'react';
import { Pie } from 'react-chartjs-2';
import NFormatterFun from '../NumberFormaterFun';
import _ from 'lodash';
let palette = window.palette;

/**
  * PieChart component
  */

const options = {
	responsive: true,
	options: {
		responsive: true,
		maintainAspectRatio: true,
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true
				}
			}]
		}
	},
	legend: {
		display: true,
		position: 'bottom',
		labels: {
			boxWidth: 10
		}
	},
	tooltips: {
		callbacks: {
			label: function (tooltipItem, data) {
				// console.log(data)
				var dataset = data.datasets[tooltipItem.datasetIndex];
				var meta = dataset._meta[Object.keys(dataset._meta)[0]];
				var total = meta.total;
				var currentValue = dataset.data[tooltipItem.index];
				var percentage = parseFloat((currentValue / total * 100).toFixed(1));
				// if (dataset.dataSymbol[tooltipItem.index][0] == 'number' || dataset.dataSymbol[tooltipItem.index][1] == 'Unit') {
				currentValue = NFormatterFun(currentValue, dataset.dataSymbol[tooltipItem.index][0], dataset.dataSymbol[tooltipItem.index][1], true)
				// }
				return currentValue + ' (' + percentage + '%)';
			},
			title: function (tooltipItem, data) {
				return data.labels[tooltipItem[0].index];
			}
		}
	}
};

class PieChart extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			data: null
		}
	}
	getData(chartData) {
		var tempData = {
			labels: [],
			datasets: []
		};
		var tempdataSet = {
			label: "",
            data: [],
            dataSymbol: []

		};

		_.map(chartData, function (k, v) {
			var plots = k['plots'];
			for (var i = 0; i < plots.length; i++) {
				tempData.labels.push(plots[i]['name']);
				//tempdataSet.data.push(NFormatterFun(plots[i]['value'], plots[i]['symbol'], this.props.GFilterData['Denomination']));
                tempdataSet.data.push(plots[i]['value']);
                tempdataSet.dataSymbol.push([plots[i]['symbol'], "Unit"]);

			}
        // }.bind(this));
				});
        tempdataSet.backgroundColor= palette('tol-dv', tempdataSet.data.length).map(function(hex) {
            return '#' + hex;
          })
		tempData.datasets.push(tempdataSet);
		return tempData;
	}

	render() {
		let { chartData } = this.props;
        let _data = this.getData(chartData)
    //     console.log("PieChart chartData", chartData);
		// console.log("PieChart _data", _data);
		if (_data) {
			return (
                <Pie
                    data={_data}
                    options={options}
                />
			)
		}
		return <div>Loading...</div>
	}
}

export default PieChart;

const Auth = {
  get(item) {
    const user = JSON.parse(localStorage.getItem("user"));
    return user[item];
  },

  isLoggedIn() {
    if (localStorage.getItem("user")) {
      return true;
    } else {
      return false;
    }
  }
};

export default Auth;

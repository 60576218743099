import React, { Component } from "react";
import BrandNavBar from "./Components/brandNavBar";
import MainNavBar from "./Components/mainNavBar";
import DetailNavBar from "./Components/detailNavBar";
import HorizontalNavBar from "./Components/horizontalNavBar";
import PropTypes from "prop-types";
import PageLayout from "./Components/pageLayout";
import { DashboardService } from "../../services/dashboard.service";
import _ from 'lodash';

/**
  * Dashboard component
  */

class Dashboard extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      dashboardConfigData : [],
      selectedTab: "",
      trigger: false,
    }
  }

  componentDidMount() {
      // console.log("PathName: "+JSON.stringify(this.props))
      DashboardService.getConfig().then(
      response => {
        this.setState(prevState => ({
          ...prevState,
          dashboardConfigData : response.responseData
        }))
      },
      error => {

      }
    );
  }

  componentWillReceiveProps(prevProps) {
    if(prevProps.history.location.state) {
      if(prevProps.history.location.state.trigger === true){
        this.setState ({
          trigger: true
        })
        if(this.state.trigger) {
          this.setState({
            trigger: false
          })
        }
      }
    }
   }

  renderCharts = () => {
    let { dashboardConfigData } = this.state;
    let tabsInitData = _.chain(dashboardConfigData).first().get("visualizations").groupBy("name").value();
    return (
      <div>
        {
          _.map(tabsInitData, (k, v) => {
            return (
              <PageLayout key={v} chartRowData={k} row={k.row} />
            )
          })
        }
      </div>
    )
  }

  render() {
    return (
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="row">
          <BrandNavBar />
          <MainNavBar pathName={this.props} history={this.props.history}/>
          <DetailNavBar history={this.props.history}/>
          <HorizontalNavBar pathName={this.props}/>
        </div>
        <div className="row tabText">
          <div className="col-md-12 mt-5 mb-5">
            {!this.state.trigger && this.state.dashboardConfigData && this.state.dashboardConfigData.length && this.renderCharts()}
            {this.state.trigger && this.state.dashboardConfigData && this.state.dashboardConfigData.length && this.renderCharts()}
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;

import { APP, LANG } from "./../constants";
import { authHeader } from "../helpers/authHeader";

export const UserService = {
  login,
  logout,
};

function login(username, password) {
  const requestOptions = {
    method: APP.REQUEST.POST,
    headers: authHeader(),
    body: JSON.stringify({ username, password })
  };

  return fetch(
    "https://pontus-api.pulz.app/login",
    requestOptions
  ).then(handleResponse);
}

function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("startDate");
  localStorage.removeItem("endDate");
  localStorage.removeItem("chartData");
  localStorage.removeItem("widgetArray");
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        // location.reload(true);
      }
      const error = LANG.APIERROR || (data && data.message) || response.statusText; //Ignoring server side error and using end user readable message
      return Promise.reject(new Error(error));
    }
    return data;
  });
}

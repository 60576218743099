import React, { Component } from 'react';
import GenericChart from '../Visualizations/GenericCharts';

/**
  * Page layout to display the charts which are generated dynamically
  */


class PageLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    }

    render() {
        let { chartRowData, row } = this.props;
        return (
            <div key={`generic${row}`}>
                { chartRowData.map((vizData, j) => <GenericChart key={j} row={row}  chartData={vizData} />)
                }
            </div>
            );
    }
}


export default PageLayout;

import React from 'react';
import { Bar } from 'react-chartjs-2';
import NFormatterFun from '../NumberFormaterFun';
// import _ from 'lodash';
let palette = window.palette;

/**
  * BarChart component
  */

const options = {
  scales: {
    xAxes: [{
        gridLines: {
            color: "rgba(0, 0, 0, 0)",
        }
    }]
  },
  responsive: true,
  options: {
    responsive: true,

    maintainAspectRatio: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  },
  legend: {
    display: true,
    position: 'bottom',
    labels: {
      boxWidth: 10
    }
  }
};

class BarChart extends React.Component {

  constructor(props) {
    super(props);
  }

  manupulateData(chartData) {
    // let temp, tempdata;
    // temp = this.props.chartData;
    var tempdata = {
      labels: [],
      datasets: []
    };
    let colors= palette('tol-dv', chartData.length).map(function(hex) {
      return '#' + hex;
    });
    chartData.map((d, i) => {
      let tempObj = {
        label: "",
        borderColor: colors[i],
        backgroundColor: colors[i],
        fill: false
      }
      let tempdataArr = [];
      let tempdatalabel = [],tempVal='';
      // let val = NFormatterFun(_.get(d,'headerValue.value'), _.get(d,'headerValue.symbol'), this.props.GFilterData['Denomination'])
      // tempObj.label = d.headerName + " : " + val;
      tempObj.label =   d.headerName;
      d.plots.map((d1, i) => {
        tempVal = NFormatterFun(d1.value, d1.symbol, 'Unit');
        tempVal = (typeof tempVal == 'string')?parseFloat(tempVal.replace(/,/g, '')):tempVal;
        tempdataArr.push(tempVal);
        tempdatalabel.push(d1.name);
      })
      tempObj.data = tempdataArr;
      tempdata.labels = tempdatalabel;
      tempdata.datasets.push(tempObj);
    })
    return tempdata;
  }

  render() {
    // let { chartData,classes } = this.props;
    let { chartData } = this.props;
    let data = this.manupulateData(chartData);
    if (data) {
        return (
                <Bar
                  style={{ fill: 'none'}}
                  data={data}
                  options={options}
                >
                </Bar>
            )
    }
    return <div>Loading...</div>
  }
}


export default BarChart;

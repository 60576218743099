import React from 'react';
import LineChart from './LineChart';
import BarChart from './BarChart';
import PieChart from './PieChart';
// import TableChart from './TableChart';
import _ from 'lodash';
import DoughnutChart from './DoughnutChart';
import HorizontalBarChart from './HorizontalBarChart';
import { DashboardService } from "../../../services/dashboard.service";

/**
  * Component to genearte the required charts
  * as per the response from the API
  */

class ChartType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartsGData: {}
        }
    }

    componentDidMount() {
        this.callAPI();
    }



    callAPI() {
        let code = _.chain(this.props).get('chartData').first().get('id').value();
        DashboardService.getData(code).then(
            response => {

              this.setState( prevState => ({
                  ...prevState,
                  chartsGData: {
                      ...prevState.chartsGData,
                      [code]:response.responseData
                  }
              }))
            },
            error => {

            }
          )
    }

    render() {
        let chartKey = _.chain(this.props).get('chartData').first().get('id').value();
        let chartType = _.chain(this.props).get('chartData').first().get('chartType').toUpper().value();
        let data = _.chain(this.state).get('chartsGData').get(chartKey).get('data').value();
        // console.log("ChartTypes chartsGData", this.state.chartsGData);
        // console.log("ChartTypes chartKey", chartKey)
        // console.log("ChartTypes props", this.props);
        // console.log("ChartTypes data", data);

        if (data) {
            // var chartData = this.state.data.responseData;
            // this.state.data = null;
            switch (chartType) {
                case 'PIE':
                    return <PieChart chartData={data}
                        label={this.props.label}
                        unit={this.state.unit}
                        GFilterData={this.props.GFilterData}
                        dimensions={this.props.dimensions}
                        section={this.props.section}
                    />
                case 'DONUT':
                    return <DoughnutChart chartData={data}
                        label={this.props.label}
                        unit={this.state.unit}
                        GFilterData={this.props.GFilterData}
                        dimensions={this.props.dimensions}
                        section={this.props.section}
                    />
                case 'LINE':
                    return <LineChart chartData={data}
                        label={this.props.label}
                        unit={this.state.unit}
                        GFilterData={this.props.GFilterData}
                        dimensions={this.props.dimensions}
                        section={this.props.section}
                    />
                case 'BAR':
                    return <BarChart chartData={data}
                        label={this.props.label}
                        unit={this.state.unit}
                        GFilterData={this.props.GFilterData}
                        dimensions={this.props.dimensions}
                        section={this.props.section}
                    />
                case 'MULTIBAR':
                    return <BarChart chartData={data}
                        label={this.props.label}
                        unit={this.state.unit}
                        GFilterData={this.props.GFilterData}
                        dimensions={this.props.dimensions}
                        section={this.props.section}
                  />
                case 'HORIZONTALBAR':
                    return <HorizontalBarChart chartData={data}
                        label={this.props.label}
                        unit={this.state.unit}
                        GFilterData={this.props.GFilterData}
                        dimensions={this.props.dimensions}
                        section={this.props.section}
                    />
                // case 'TABLE':
                //     return <TableChart chartData={data}
                //         chartKey={chartKey}
                //         chartParent={this.props.chartData}
                //         unit={this.state.unit}
                //         GFilterData={this.props.GFilterData}
                //         filters={this.props.filters}
                //         dimensions={this.props.dimensions}
                //         section={this.props.section}
                //         label={this.props.label}
                //         page={this.props.page}
                //     />
                default:
                    return false;
            }
        }
        return <div > Loading... </div>
    }
}

export default ChartType;

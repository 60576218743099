import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

/**
 * Horizontal navbar component for Dashboards
 */

class HorizontalNavBar extends Component {
  
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div className="col-sm-12 col-md-12 col-xl-12 col-lg-12 horizontalText">
        <div className="row col-sm-12 col-md-12 col-xl-12 col-lg-12">
          <NavLink exact className="tabActive paddingTop10 pl-4 pr-4 pt-3" to="/" style={{marginLeft: "-15px"}}><hr className="topLine"/> Customer Satisfaction
          <span className="sr-only">(current)</span></NavLink>
        </div>
      </div>
    )
  }
}

export default HorizontalNavBar;

import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import * as moment from 'moment';
import { UserService } from "../../../services/user.service";

/**
 * Main navbar component
 */

class MainNavBar extends Component {

  constructor(props) {
    super(props);
    this.state = { active: false, currentDate: "", currentUser: ""}
    this.getCurrentDate = this.getCurrentDate.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.getCurrentDate();
  }

  /**
    * Function to get current date, year, month and
    * converting those into the required format
    * using moment.js
    */
  async getCurrentDate() {
    let date = new Date();
    await this.setState({
      currentDate: moment(date).format('dddd, Do MMMM YYYY')
    })
    let userDetails = localStorage.getItem("user");
    userDetails = JSON.parse(userDetails);
    let userName = userDetails.username;
    let name = userName.split('@');
    this.setState({
      currentUser: name[0]
    })
  }

  logout = () => {
    UserService.logout();
    this.props.history.push("/login");
  }

  render() {
    return (
      <div className="row col-sm-12 col-md-10 col-lg-10 col-xl-10 navHeight navBg tabText mainNavBarbuiltBorder">
        {/*<NavLink exact activeClassName="active" className="anchor paddingTop10 pl-4 pr-4 pt-3" to="/home" style={{marginLeft: "-16px"}}>Home
          <hr className={this.props.pathName.location.pathname === "/home"? 'btmLine' : 'btmLineNone'}/> <span className="sr-only">(current)</span></NavLink>*/}
        <NavLink exact activeClassName="active" className="anchor paddingTop10 pl-4 pr-4 pt-3" to="/dashboards" style={{marginLeft: "-16px"}}>Dashboards
          <hr className={this.props.pathName.location.pathname === "/dashboards" ? 'btmLine' : 'btmLineNone'}/></NavLink>
        {/*<NavLink exact activeClassName="active" className="anchor paddingTop10 pl-4 pr-4 pt-3" to="/settings">Settings
          <hr className={this.props.pathName.location.pathname === "/settings" ? 'btmLine' : 'btmLineNone'}/></NavLink>*/}

        <div className="moveRight paddingTop10 textColor">
          <div className="row">
            <p className="mr-5 d-none d-md-none d-lg-flex">{this.state.currentDate}</p>
            <div className="dropdown mr-5 d-none d-md-flex d-lg-flex">
              <p className="dropdown-toggle mr-5" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Sweden &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <div className="dropdown-menu mr-5" aria-labelledby="dropdownMenuLink">
                 <p className="dropdown-item" href="#">Sweden</p>
               </div>
            </div>
            {/*<i className="d-sm-flex d-md-none d-lg-none fas fa-sort-down"></i>*/}
            <p className="d-md-none d-lg-none d-sm-flex pr-2">{this.state.currentUser}</p>
            <span onClick={this.logout} className="pr-2"><img className="d-sm-flex d-md-none d-lg-none cursorStyleOne" src="img/logout.png" width="18" height="18" alt="user logout"/></span>
            <p className="mr-5 d-none d-md-flex d-lg-flex">{this.state.currentUser}<span className="pl-4 cursorStyleOne" onClick={this.logout}><img src="img/logout.png" width="20" height="20" alt="user logout"/></span></p>
          </div>
        </div>
      </div>
    )
  }
}

export default MainNavBar;

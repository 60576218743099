import React from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
import Dashboard from "./components/Dashboard/dashboard";
import Login from "./components/Login/login";
import Auth from "./helpers/auth";

const Router = props => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/login" component={Login} />
      <PrivateRoute exact path="/home" component={Dashboard} />
      <PrivateRoute exact path="/dashboards" component={Dashboard} />
      <PrivateRoute exact path="/settings" component={Dashboard} />
    </Switch>
  </BrowserRouter>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Auth.isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )
    }
  />
);

export default Router;

import { APP, LANG } from "../constants";
import { authHeader } from "../helpers/authHeader";
import * as moment from 'moment';

export const DashboardService = {
  getConfig,
  getData,
};

let sDate, eDate;

function getConfig() {
  const requestOptions = {
    method: APP.REQUEST.GET,
    headers: authHeader()
  };
  return fetch(
    "https://pontus-api.pulz.app/dashboard/getDashboardConfig/Pulz/home",
    requestOptions
  ).then(handleResponse);
}

function getData(code) {
    sDate = localStorage.getItem("startDate");
    eDate = localStorage.getItem("endDate");
    let reqObj ;

    if(sDate && eDate ) {
      sDate = moment(sDate);
      eDate = moment(eDate);
      let startDate = Number(sDate);
      let endDate = Number(eDate);
      let duration = {"startDate":  startDate, "endDate":  endDate}
      // console.log("Duration: "+JSON.stringify(duration));
      reqObj ={
          "RequestInfo":{
              "authToken": "null"
          },
          "headers": {
              "tenantId": "null"
          },

          "aggregationRequestDto": {
              "visualizationType": "METRIC",
              "visualizationCode": code,
              "queryType": "",
              "filters": {},
              "moduleLevel": "",
              "aggregationFactors": null,
              "requestDate": duration
          }
    }

      // console.log("Present");
    } else {
      let startRange = moment().startOf('month');
      let endRange = moment().endOf('month');
      startRange = Number(startRange);
      endRange = Number(endRange);
      let thisMonth = {"startDate":  startRange, "endDate":  endRange}
      // console.log("thisMonth: "+JSON.stringify(thisMonth));
      reqObj ={
          "RequestInfo":{
              "authToken": "null"
          },
          "headers": {
              "tenantId": "null"
          },

          "aggregationRequestDto": {
              "visualizationType": "METRIC",
              "visualizationCode": code,
              "queryType": "",
              "filters": {},
              "moduleLevel": "",
              "aggregationFactors": null,
              "requestDate": thisMonth
          }
    }

      // console.log("Absent");
    }

    const requestOptions = {
        method: APP.REQUEST.POST,
        body: JSON.stringify(reqObj),
        headers: authHeader()
      };
      return fetch(
        "https://pontus-api.pulz.app/dashboard/getChartV2/Pulz",
        requestOptions
      ).then(handleResponse);
  }

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = LANG.APIERROR || (data && data.message) || response.statusText;
      return Promise.reject(new Error(error));
    }
    return data;
  });
}

import React from 'react';
import ChartType from './ChartType';

/**
  * GenericChart component to display the
  * generated charttypes in the page layout
  */

class GenericChart extends React.Component {

    renderCharts(d, chartData, index) {
        switch (d.vizType.toUpperCase()) {
            case 'CHART':
                return (
                    <div className={`col-sm-12 col-md-${d.dimensions.width} col-lg-${d.dimensions.width} mt-2 mb-3`}>
                        <div className="chart-wrapper h-100 cardChart p-3">
                            <h5 className="chartText">{d.name}</h5>
                            <ChartType key={index} chartData={d.charts} label={d.name} section={chartData.name}/>
                        </div>
                    </div>
                    )
            default:
                return <div></div>
        }
    }
    render() {
        let { chartData, row } = this.props;
        
        return (
            <div key={row} className="row">
                {chartData.vizArray.map((d, i) => this.renderCharts(d, chartData, i))}
            </div>
        );
    }
}


export default GenericChart;

export const APP = {
  REQUEST: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE"
  },
  CODE: {
    SUCCESS: 200
  },
};
